import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes ? (
                                        renderRoutes(route.routes)
                                    ) : (
                                        <Component {...props} />
                                    )}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        path: "/404",
        component: lazy(() => import("src/views/errors/NotFoundView")),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/login",
        component: lazy(() => import("src/views/auth/LoginView")),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/register/:email?",
        component: lazy(() => import("src/views/auth/RegisterView")),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/invitation/:email?",
        component: lazy(() => import("src/views/auth/InviteView")),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/verify-email/:id",
        component: lazy(() => import("src/views/auth/VerifyEmailView")),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/forgot-password",
        component: lazy(() => import("src/views/auth/ForgotPW")),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: "/reset-password/:token",
        component: lazy(() => import("src/views/auth/ResetPW")),
    },
    {
        exact: true,
        path: "/report/:id/:tab?",
        component: lazy(() =>
            import("src/views/prediction/PublicReportingView")
        ),
    },
    {
        path: "/*",
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: "/account/:tab?",
                component: lazy(() => import("src/views/account/AccountView")),
            },
            {
                exact: true,
                path: "/admin/:tab?/:id?",
                component: lazy(() => import("src/views/admin/AdminView")),
            },
            {
                exact: true,
                path: "/new-plan",
                component: lazy(() => import("src/views/account/PricingView")),
            },
            {
                exact: true,
                path: "/checkout/:price_id",
                component: lazy(() => import("src/views/account/CheckoutView")),
            },
            {
                exact: true,
                path: "/plugins",
                component: lazy(() => import("src/views/plugins/PluginsView")),
            },
            {
                exact: true,
                path: "/prediction/:id/:tab?",
                component: lazy(() =>
                    import("src/views/prediction/ReportingView")
                ),
            },
            {
                exact: true,
                path: "/comparison/:id/:tab?",
                component: lazy(() =>
                    import("src/views/comparison/ComparisonView")
                ),
            },
            {
                exact: true,
                path: "/guide/:id/:tab?",
                component: lazy(() =>
                    import("src/views/guide/GuideView")
                ),
            },
            {
                exact: true,
                path: "/predictions/create/:projectId?",
                component: lazy(() =>
                    import("src/views/prediction/PredictionCreateView")
                ),
            },
            {
                exact: true,
                path: "/predictions",
                component: lazy(() =>
                    import("src/views/prediction/PredictionListView")
                ),
            },
            {
                exact: true,
                path: "/projects/:id",
                component: lazy(() =>
                    import("src/views/project/ProjectDetailsView")
                ),
            },
            {
                exact: true,
                path: "/projects",
                component: lazy(() =>
                    import("src/views/project/ProjectListView")
                ),
            },
            {
                exact: true,
                path: "/comparisons",
                component: lazy(() =>
                    import("src/views/comparison/ComparisonListView")
                ),
            },
            {
                exact: true,
                path: "/",
                component: lazy(() =>
                    import("src/views/home/DashboardView")
                ),
            },
            {
                component: () => <Redirect to="/404" />,
            },
        ],
    },
];

export default routes;
